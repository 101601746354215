import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="27" height="27" viewBox="0 0 27 27" {...props}>
<path d="M21.7969 16.9908C22.5268 15.2564 22.6949 13.3368 22.2774 11.502C21.8598 9.6671 20.8777 8.00924 19.4692 6.76146C18.0606 5.51367 16.2964 4.73872 14.4246 4.54555C12.5528 4.35239 10.6675 4.75072 9.03376 5.68455L7.91776 3.73042C9.6251 2.75462 11.5585 2.24359 13.525 2.24832C15.4915 2.25306 17.4224 2.7734 19.125 3.75742C24.1763 6.67342 26.1113 12.9172 23.7566 18.1237L25.2664 18.9944L20.5808 21.4852L20.3951 16.1819L21.7969 16.9908ZM5.20314 10.009C4.47318 11.7435 4.30514 13.663 4.72267 15.4979C5.1402 17.3327 6.12228 18.9906 7.53085 20.2384C8.93941 21.4862 10.7036 22.2611 12.5754 22.4543C14.4473 22.6475 16.3326 22.2491 17.9663 21.3153L19.0823 23.2694C17.3749 24.2452 15.4415 24.7563 13.475 24.7515C11.5085 24.7468 9.57763 24.2264 7.87501 23.2424C2.82376 20.3264 0.888763 14.0827 3.24339 8.87617L1.73251 8.00655L6.41814 5.5158L6.60376 10.819L5.20201 10.0102L5.20314 10.009ZM9.56251 15.7499H15.75C15.8992 15.7499 16.0423 15.6907 16.1478 15.5852C16.2533 15.4797 16.3125 15.3366 16.3125 15.1874C16.3125 15.0382 16.2533 14.8952 16.1478 14.7897C16.0423 14.6842 15.8992 14.6249 15.75 14.6249H11.25C10.5041 14.6249 9.78872 14.3286 9.26128 13.8012C8.73383 13.2737 8.43751 12.5583 8.43751 11.8124C8.43751 11.0665 8.73383 10.3511 9.26128 9.82369C9.78872 9.29624 10.5041 8.99992 11.25 8.99992H12.375V7.87492H14.625V8.99992H17.4375V11.2499H11.25C11.1008 11.2499 10.9578 11.3092 10.8523 11.4147C10.7468 11.5202 10.6875 11.6632 10.6875 11.8124C10.6875 11.9616 10.7468 12.1047 10.8523 12.2102C10.9578 12.3157 11.1008 12.3749 11.25 12.3749H15.75C16.4959 12.3749 17.2113 12.6712 17.7388 13.1987C18.2662 13.7261 18.5625 14.4415 18.5625 15.1874C18.5625 15.9333 18.2662 16.6487 17.7388 17.1762C17.2113 17.7036 16.4959 17.9999 15.75 17.9999H14.625V19.1249H12.375V17.9999H9.56251V15.7499Z" fill="white"/>
      </Svg>
  );
};

export default Icon;
