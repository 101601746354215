import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'



export const DEFAULT_META: PageMeta = {
  title: 'LiveTrade DApp - Digital Assets and Tokenized Stocks in a Single Platform',
  // description:
  //   'A multi-chain platform that allows continuous flows between digital and traditional financial assets. This is where values are connected and best exploited.',
  
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Dashboard')} | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/livetrade-preview.png',
        description:"A multi-chain platform that allows continuous flows between digital and traditional financial assets. This is where values are connected and best exploited."
      }
    case '/farms':
      return {
        title: `${t('Earn')} | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/livetrade-preview.png',
        description:"Earn feature enables users to earn passive income by farming and staking. Utilize your assets to enjoy more benefits."
      }
    case '/pools':
      return {
        title: `${t('Earn')} | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/livetrade-preview.png',
        description:"Earn feature enables users to earn passive income by farming and staking. Utilize your assets to enjoy more benefits."
      }
    case '/membership':
      return {
        title: `Membership | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/previewimages/NFTCollections.png',
        description:"The feature provides LiveTrade's members with exclusive privileges. Become our member to enjoy more interests."
      }
    case '/privateshare/live':
      return {
        title: `${t('Private Share')} | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/previewimages/PrivateShare.png',
        description:"Private Share is an exclusive feature for LiveTrade members. It provides members with private allocations of our DIPO or joint projects."
      }
    case '/privateshare/next':
      return {
        title: `${t('Private Share')} | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/previewimages/PrivateShare.png',
        description:"Private Share is an exclusive feature for LiveTrade members. It provides members with private allocations of our DIPO or joint projects."
      }
    case '/privateshare/past':
      return {
        title: `${t('Private Share')} | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/previewimages/PrivateShare.png',
        description:"Private Share is an exclusive feature for LiveTrade members. It provides members with private allocations of our DIPO or joint projects."
      }
    case '/nftmarketplace':
      return {
          title: `${t('NFT Marketplace')} | ${t('LiveTrade')}`,
          image: 'https://dapp.livetrade.io/images/livetrade-preview.png',
          description:
    'NFT Marketplace is where users can buy and sell LiveTrade Membership NFTs, which enable users to generate their profits by trading.',
          
      }
    case '/dipo/live':
      return {
        title: `${t('DIPO')} | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/livetrade-preview.png',
        description:"DIPO feature brings more investment opportunities to LiveTraders by allowing users to buy tokens released by our DIPO projects at a preferential price."
      }
    case '/dipo/next':
      return {
        title: `${t('DIPO')} | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/livetrade-preview.png',
        description:"DIPO feature brings more investment opportunities to LiveTraders by allowing users to buy tokens released by our DIPO projects at a preferential price."
      }
    case '/dipo/past':
      return {
        title: `${t('DIPO')} | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/livetrade-preview.png',
        description:"DIPO feature brings more investment opportunities to LiveTraders by allowing users to buy tokens released by our DIPO projects at a preferential price."
      }
    case '/migration':
      return {
        title: `${t('Migration')} | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/previewimages/Migration.png',
        description:"Migration is a feature set out to allow users to migrate current LTD to new LTD for receiving their privileges."
      }
    case '/repo':
      return {
        title: `${t('Repo')} | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/livetrade-preview.png',
        description:
    'A multi-chain platform that allows continuous flows between digital and traditional financial assets. This is where values are connected and best exploited.',
      }
    case '/voting':
      return {
        title: `${t('Voting')} | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/previewimages/Voting.png',
        
        description:"Voting feature allows LiveTrade members to vote on major decisions of the ecosystem. You will be one of the contributors to our success."
      }
    case '/airdrop':
      return {
        title: `${t('Airdrop')} | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/livetrade-preview.png',
        description:
        'A multi-chain platform that allows continuous flows between digital and traditional financial assets. This is where values are connected and best exploited.',
      }
    case '/swap':
      return {
        title: `${t('Exchange')} | ${t('LiveTrade')}`,
        image: 'https://dapp.livetrade.io/images/livetrade-preview.png',
        description:
    'A multi-chain platform that allows continuous flows between digital and traditional financial assets. This is where values are connected and best exploited.',
      }
    case '/liquidity':
        return {
          title: `${t('Exchange')} | ${t('LiveTrade')}`,
          image: 'https://dapp.livetrade.io/images/livetrade-preview.png',
          description:
    'A multi-chain platform that allows continuous flows between digital and traditional financial assets. This is where values are connected and best exploited.',
        }
    default:
      return null
  }
} 
