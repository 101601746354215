import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 50 50" {...props} width="80%">
       <g clipPath="url(#clip0_770:131079)">
<path d="M48.899 33.7378L45.4803 28.5586L42.0053 30.8503L45.4157 36.0419C45.6562 36.3924 45.7945 36.8029 45.8151 37.2274C45.8357 37.652 45.7378 38.0739 45.5323 38.4461C45.3247 38.8342 45.0143 39.1577 44.6351 39.3811C44.2559 39.6045 43.8225 39.7193 43.3823 39.7127H26.8303L29.9095 36.6398L26.9678 33.6898L21.7116 38.9336C21.3237 39.3237 21.0165 39.7864 20.8076 40.2953C20.5988 40.8042 20.4922 41.3493 20.4942 41.8993C20.4961 42.4494 20.6065 42.9938 20.8189 43.5012C21.0314 44.0086 21.3418 44.4691 21.7324 44.8565L26.9803 50.0002L29.897 47.0273L26.6845 43.8711H43.3907C44.5822 43.875 45.7525 43.5559 46.7771 42.9476C47.8017 42.3394 48.6423 41.4648 49.2094 40.4169C49.7773 39.3828 50.0488 38.2121 49.994 37.0337C49.9392 35.8552 49.5603 34.7147 48.899 33.7378Z"/>
<path d="M24.501 6.27771C24.9087 6.27639 25.3105 6.37479 25.6715 6.56434C26.0324 6.75388 26.3415 7.02883 26.5719 7.36521L35.474 20.834L30.8594 19.9235L30.0552 24.011L37.5969 25.4985C38.137 25.6055 38.693 25.6042 39.2327 25.495C39.7724 25.3857 40.2851 25.1705 40.7411 24.8619C41.1971 24.5533 41.5875 24.1574 41.8895 23.697C42.1916 23.2367 42.3995 22.721 42.501 22.1798L43.926 14.5465L39.8281 13.7819L38.9427 18.5298L30.049 5.06937C29.4379 4.14755 28.6055 3.39365 27.6278 2.87664C26.6502 2.35963 25.5585 2.09601 24.4526 2.1099C23.3467 2.12378 22.262 2.41473 21.2976 2.95612C20.3332 3.49752 19.52 4.27208 18.9323 5.20896L16.3594 9.30896L19.8865 11.5256L22.4656 7.41937C22.6788 7.0708 22.978 6.78279 23.3343 6.5829C23.6907 6.383 24.0924 6.27792 24.501 6.27771Z"/>
<path d="M13.4529 21.7757L14.3488 26.5882L18.4467 25.8319L17.0217 18.1903C16.9203 17.6493 16.7128 17.1338 16.4111 16.6735C16.1094 16.2132 15.7195 15.8173 15.2639 15.5085C14.8083 15.1997 14.296 14.9843 13.7567 14.8746C13.2174 14.7649 12.6617 14.7632 12.1217 14.8694L4.58417 16.3549L5.39042 20.4424L9.94042 19.5444L0.996672 33.7694C0.375603 34.7522 0.032516 35.8848 0.00390204 37.047C-0.0247119 38.2092 0.262218 39.3574 0.834172 40.3694C1.40139 41.4017 2.23767 42.261 3.25415 42.856C4.27063 43.4509 5.42932 43.7594 6.60709 43.7486H14.5842V39.5819H6.60709C6.17289 39.5876 5.74527 39.4755 5.36965 39.2576C4.99403 39.0397 4.68444 38.7241 4.47376 38.3444C4.26683 37.9822 4.16271 37.5703 4.17262 37.1533C4.18253 36.7362 4.3061 36.3298 4.53 35.9778L13.4529 21.7757Z"/>
</g>
<defs>
<clipPath id="clip0_770:131079">
<rect width="50" height="50"/>
</clipPath>
</defs>
    </Svg>
  );
};

export default Icon;
