import React from "react";
import styled from 'styled-components'
import { SvgProps } from "../../../components/Svg/types";

const StyleDiv = styled.div`
  padding-right: 0px;
`
const Icon: React.FC<SvgProps> = (props) => {
  return (
      <StyleDiv>
        <svg  viewBox="0 0 26 28"  {...props}>
          <path d="M6.05721 6.06301L5.71558 6.40625L10.629 11.3428L10.9706 10.9995L6.05721 6.06301Z"/>
          <path d="M18.3444 9.5799H9.2356V18.7315H18.3444V9.5799Z" />
          <path d="M7.58533 0.214661H0V7.83565H7.58533V0.214661Z" />
          <path d="M10.0203 1.2135V5.5477H18.0641C19.0499 5.54827 19.9952 5.94206 20.6922 6.64251C21.3891 7.34296 21.7808 8.29278 21.7811 9.28323V18.8124C21.7805 19.8025 21.3886 20.7518 20.6916 21.4517C19.9946 22.1516 19.0495 22.5447 18.0641 22.5447H5.71713V10.2821H1.19385V27.0968H17.5348C18.6423 27.0968 19.739 26.8776 20.7622 26.4518C21.7854 26.026 22.7151 25.4019 23.4982 24.6151C24.2813 23.8283 24.9025 22.8942 25.3263 21.8662C25.7502 20.8382 25.9683 19.7364 25.9683 18.6237V9.68342C25.9683 8.57071 25.7502 7.4689 25.3263 6.44089C24.9025 5.41288 24.2813 4.4788 23.4982 3.692C22.7151 2.90519 21.7854 2.28106 20.7622 1.85525C19.739 1.42943 18.6423 1.21027 17.5348 1.21027L10.0203 1.2135Z"/>
        </svg>
      </StyleDiv>
  );
};

export default Icon;
