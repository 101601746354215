import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 25 25" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 0C5.59547 0 0 5.46497 0 12.2085C0 18.952 5.59547 24.417 12.5 24.417C19.4045 24.417 25 18.952 25 12.2085C25 5.46763 19.4045 0 12.5 0ZM17.8641 10.2282C17.8696 10.3399 17.8723 10.4515 17.8723 10.5658C17.8723 14.0027 15.1916 17.9685 10.2928 17.9685C8.78783 17.9685 7.38896 17.5379 6.20782 16.799C6.41737 16.8229 6.62965 16.8362 6.84193 16.8362C8.09112 16.8362 9.2396 16.4188 10.1513 15.7224C8.9865 15.7012 8.0013 14.9489 7.66111 13.915C7.82441 13.9442 7.99042 13.9628 8.16187 13.9628C8.40409 13.9628 8.64086 13.9309 8.86403 13.8724C7.64478 13.6332 6.72763 12.5806 6.72763 11.3207C6.72763 11.3101 6.72763 11.2994 6.72763 11.2861C7.08687 11.4802 7.49782 11.5998 7.93327 11.6104C7.2175 11.1453 6.74668 10.3478 6.74668 9.44409C6.74668 8.9683 6.88003 8.52175 7.10864 8.13633C8.42314 9.7099 10.3854 10.7465 12.6007 10.8555C12.5544 10.6641 12.5327 10.4674 12.5327 10.2628C12.5327 8.82742 13.7247 7.66053 15.197 7.66053C15.9645 7.66053 16.6558 7.97684 17.1429 8.48187C17.7498 8.36492 18.3186 8.14962 18.833 7.84926C18.6343 8.45529 18.2125 8.96564 17.6627 9.28727C18.2016 9.22347 18.716 9.08526 19.1922 8.87793C18.8357 9.40422 18.384 9.86141 17.8641 10.2282Z" />
    </Svg>
  );
};

export default Icon;
