import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ACTIVE_SCREEN, InitialState, ItemsHistoryProfitSharingType } from './type';
import { fetchDataShares, getAllLiveTradeShares, getOneLiveTradeShares } from './actions';

const initialState: InitialState = {
    isSeeAllAcquisition: false,
    isSeeAllHistory: false,
    dialogTransactionDetails: null,
    activeScreen: ACTIVE_SCREEN.MAIN,
    dialogWithdraw: null,
    dialogDeposit: null,
    historyDetail: null,
    proposeDetail: null,
    shares: null,
    transactionHash: "",
    isLoading: false,
    dataHistoryProfitSharing: {
        items: [],
        total: 0,
        isLoading: false,
    },
    dataOneLiveTradeShares: {
        data: null,
        isLoading: false,
    }
}
export const sharesSlice = createSlice({
    name: "nav",
    initialState,
    reducers: {
        setSeeAllAcquisition: (state, action) => {
            state.isSeeAllAcquisition = action.payload
        },
        setSeeAllHistory: (state, action) => {
            state.isSeeAllHistory = action.payload
        },
        setDialogTransactionDetail: (state, action) => {
            state.dialogTransactionDetails = action.payload
        },
        setAcctiveScreen: (state, action) => {
            state.activeScreen = action.payload
        },
        setHistoryDetail: (state, action) => {
            state.historyDetail = action.payload
        },
        setProposeDetail: (state, action) => {
            state.proposeDetail = action.payload
        },
        setDialogWithdraw: (state, action) => {
            state.dialogWithdraw = action.payload
        },
        setDialogDepposit: (state, action) => {
            state.dialogDeposit = action.payload
        },
        setTransactionHash: (state, action) => {
            state.transactionHash = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchDataShares.pending, (state, action) => {
                state.isLoading = !false
            })
            .addCase(fetchDataShares.fulfilled, (state, action) => {
                const poolData = action.payload
                state.shares = poolData.shares
                state.isLoading = false
            })
            .addCase(fetchDataShares.rejected, (state, action) => {
                state.isLoading = false
            })
            .addCase(getAllLiveTradeShares.pending, (state) => {
                state.dataHistoryProfitSharing = {
                    ...state.dataHistoryProfitSharing,
                    isLoading: true,
                }
            })
            .addCase(getAllLiveTradeShares.fulfilled, (state, action) => {
                state.dataHistoryProfitSharing = {
                    ...state.dataHistoryProfitSharing,
                    items: action.payload.items,
                    total: action.payload.total,
                    isLoading: false,
                }
            })
            .addCase(getAllLiveTradeShares.rejected, (state) => {
                state.dataHistoryProfitSharing = {
                    ...state.dataHistoryProfitSharing,
                    isLoading: false,
                }
            })
            .addCase(getOneLiveTradeShares.pending, (state) => {
                state.dataOneLiveTradeShares = {
                    ...state.dataOneLiveTradeShares,
                    isLoading: true,
                }
            })
            .addCase(getOneLiveTradeShares.fulfilled, (state, action) => {
                state.dataOneLiveTradeShares = {
                    ...state.dataOneLiveTradeShares,
                    data: action.payload,
                    isLoading: false,
                }
            })
            .addCase(getOneLiveTradeShares.rejected, (state) => {
                state.dataOneLiveTradeShares = {
                    ...state.dataOneLiveTradeShares,
                    isLoading: false,
                }
            })
    },
});
const { actions } = sharesSlice;
export const {
    setSeeAllHistory,
    setSeeAllAcquisition,
    setDialogTransactionDetail,
    setAcctiveScreen,
    setHistoryDetail,
    setProposeDetail,
    setDialogWithdraw,
    setDialogDepposit,
    setTransactionHash
} = actions;

export default sharesSlice.reducer
