import React from "react";
import useTheme from 'hooks/useTheme'
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    const { theme } = useTheme()
    const renderColor = () => {
       return theme.isDark ? "#EFF0FF" : "#101133"
    }
  return (
    <Svg viewBox="0 0 44 44" {...props}>
        <g clipPath="url(#clip0_2336_110253)">
        <g filter="url(#filter0_dd_2336_110253)">
        <path d="M22.0006 22H7.33398M22.0006 36.6667V22V36.6667ZM22.0006 22V7.33337V22ZM22.0006 22H36.6673H22.0006Z" stroke={renderColor()} strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" shapeRendering="crispEdges"/>
        </g>
        </g>
        <defs>
        <filter id="filter0_dd_2336_110253" x="-1.16602" y="-1.16663" width="46.334" height="46.3334" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="3"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.624117 0 0 0 0 0.639153 0 0 0 0 1 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2336_110253"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="1.5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.0231869 0 0 0 0 0.340403 0 0 0 0 0.633218 0 0 0 1 0"/>
        <feBlend mode="normal" in2="effect1_dropShadow_2336_110253" result="effect2_dropShadow_2336_110253"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_2336_110253" result="shape"/>
        </filter>
        <clipPath id="clip0_2336_110253">
        <rect width="44" height="44" fill="white"/>
        </clipPath>
        </defs>
    </Svg>
  );
};

export default Icon;
