import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg
      width="20"
      height="18"
      style={{ width: "20px", height: "18px" }}
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2502 13.5C18.7564 13.5 19.1668 13.9104 19.1668 14.4167C19.1668 14.923 18.7564 15.3334 18.2502 15.3334H5.41683C4.91057 15.3334 4.50016 14.923 4.50016 14.4167C4.50016 13.9104 4.91057 13.5 5.41683 13.5H18.2502ZM18.2502 7.08335C18.7564 7.08335 19.1668 7.49374 19.1668 8.00002C19.1668 8.50629 18.7564 8.91669 18.2502 8.91669H5.41683C4.91057 8.91669 4.50016 8.50629 4.50016 8.00002C4.50016 7.49374 4.91057 7.08335 5.41683 7.08335H18.2502ZM18.2502 0.666687C18.7564 0.666687 19.1668 1.0771 19.1668 1.58335C19.1668 2.08961 18.7564 2.50002 18.2502 2.50002H5.41683C4.91057 2.50002 4.50016 2.08961 4.50016 1.58335C4.50016 1.0771 4.91057 0.666687 5.41683 0.666687H18.2502ZM0.833496 0.666687H2.66683V2.50002H0.833496V0.666687ZM0.833496 7.08335H2.66683V8.91669H0.833496V7.08335ZM0.833496 13.5H2.66683V15.3334H0.833496V13.5Z"
        fill="black"
      />
    </Svg>
  )
}

export default Icon
