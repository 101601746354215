import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 11 11"  {...props}>
      <path d="M8.15039 10.5293H1.27539C0.94387 10.5293 0.625928 10.3976 0.391507 10.1632C0.157087 9.92876 0.0253906 9.61082 0.0253906 9.2793V2.4043C0.0253906 2.07278 0.157087 1.75483 0.391507 1.52041C0.625928 1.28599 0.94387 1.1543 1.27539 1.1543H3.77539V2.4043H1.27539V9.2793H8.15039V6.7793H9.40039V9.2793C9.40039 9.61082 9.26869 9.92876 9.03427 10.1632C8.79985 10.3976 8.48191 10.5293 8.15039 10.5293ZM4.83789 6.59617L3.95664 5.71242L7.88977 1.7793H5.65039V0.529297H10.0254V4.9043H8.77539V2.66367L4.83789 6.59617Z"/>
    </Svg>
  );
};

export default Icon;
