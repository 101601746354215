import styled from "styled-components";

export const PriceCard = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const GirdCard = styled.div`

`

export const ImageToken = styled.img`
  object-fit: contain;
  margin-right: 8px;
  width: 38px;
  height: 38px;
`
