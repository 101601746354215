import { axiosClient } from "./axios-client"
import { ClaimHistoryType, ItemsHistoryProfitSharingType } from '../state/shares/type';

export interface ParamsGetAllLiveTradeSharesType {
    skip: number | string;
    limit: number | string;
    sort?: number | string;
}
export const apiLiveTradeShares = {
    getAll: ({ skip, limit, sort }: ParamsGetAllLiveTradeSharesType): Promise<{ items: ItemsHistoryProfitSharingType[]; total: number }> => {
        const paramsSort = sort || -1;
        const url = `/cycles?skip=${skip}&limit=${limit}&isPublish=true&sort=${paramsSort}`;
        return axiosClient.get(url);
    },
    getOne: (id: string): Promise<ItemsHistoryProfitSharingType> => {
        return axiosClient.get(`/cycles/${id}`);
    },
    getClaimHistory: ({ user, cycle }: { user: string, cycle: number | string }): Promise<ClaimHistoryType> => {
        return axiosClient.get(`/claim-history?user=${user}&cycle=${cycle}`);
    }
}