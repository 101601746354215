import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 25 25" {...props} xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 12.5C0 19.4036 5.59644 25 12.5 25C19.4036 25 25 19.4036 25 12.5C25 5.59644 19.4036 0 12.5 0C5.59644 0 0 5.59644 0 12.5ZM10.2083 18.2291L10.421 15.0428L10.4208 15.0427L16.2171 9.81195C16.4715 9.58618 16.1616 9.47609 15.8239 9.68087L8.67043 14.1939L5.58053 13.2295C4.91325 13.0252 4.90846 12.5666 5.73034 12.237L17.771 7.59417C18.3209 7.3445 18.8517 7.72625 18.6417 8.56794L16.5912 18.2308C16.448 18.9174 16.0331 19.0816 15.4583 18.7645L12.3347 16.4567L10.8333 17.9166C10.8286 17.9212 10.8239 17.9258 10.8192 17.9304C10.6512 18.0939 10.5123 18.2291 10.2083 18.2291Z" />
    </Svg>
  );
};

export default Icon;
