import BigNumber from "bignumber.js";
import ERC20_ABI from 'config/abi/erc20.json';
import sharesAbi from "config/abi/shares_abi.json"
import { Token } from "config/constants/types";
import { getAddress } from "utils/addressHelpers";
import multicall from "utils/multicall";
import { DEFAULT_V_LTD_POOL } from "config";
import { DataUser, Shares, UserStakedItem } from "./type";

export const getDataPool = async (account: string, token: Token, poolAddress: string): Promise<{ shares: Shares }> => {
    const [resultDataUser, resPublictPool, resUserStaked] = await Promise.all([
        fetchDataUser(account, token, poolAddress),
        fetchPublicPool(poolAddress, token.decimals),
        fetchUserStaked(account, token.decimals, poolAddress)
    ])
    
    return {
        shares: {
            dataUser: resultDataUser.dataUser,
            ...resPublictPool,
            ...resUserStaked,
            token,
            poolAddress
        }
    }
};


const fetchDataUser = async (account: string, token: Token, poolAddress: string): Promise<{ dataUser: DataUser }> => {
    const tokenAddress = getAddress(token.address)
    if (!account) {
        return {
            dataUser: {
                balanceOf: "0",
                allownace: "0"
            }
        }
    }
    try {
        const calls = [
            {
                address: tokenAddress,
                name: 'allowance',
                params: [account, poolAddress]
            },
            {
                address: tokenAddress,
                name: 'balanceOf',
                params: [account]
            }
        ]
        const [resAllowance, resBalance] = await multicall(ERC20_ABI, calls);

        return {
            dataUser: {
                balanceOf: new BigNumber(resBalance?.balance?.toString()).dividedBy(10 ** token.decimals).toString(),
                allownace: new BigNumber(resAllowance?.toString()).toString(),
            }
        }
    } catch (error) {
        console.log("Can not fetch data user", error)
        return {
            dataUser: {
                balanceOf: "0",
                allownace: "0"
            }
        }
    }

}

const fetchPublicPool = async (poolAddress: string, tokenDecimals: number) => {
    try {
        const calls = [
            {
                address: poolAddress,
                name: 'totalVLTD',
                params: []
            },
            {
                address: poolAddress,
                name: 'paused',
                params: []
            }
        ]

        const callsPoolInfo = [...Array(DEFAULT_V_LTD_POOL)].map((dt, index) => {
            return {
                address: poolAddress,
                name: 'pools',
                params: [index + 1]
            }
        })
        const callsStakedByPoolId = [...Array(DEFAULT_V_LTD_POOL)].map((dt, index) => {
            return {
                address: poolAddress,
                name: 'totalLTDStaked',
                params: [index + 1]
            }
        })
        const [ [resTotalVLtd, resPaused], resPools, resPoolStaked ] = await Promise.all([
            multicall(sharesAbi, calls),
            multicall(sharesAbi, callsPoolInfo),
            multicall(sharesAbi, callsStakedByPoolId)
        ])
        
        const convertPools = resPools.map((dt, index) => {
            return {
                poolId: new BigNumber(dt?.id?.toString()).toNumber(),
                rating: new BigNumber(dt?.rating?.toString()).toNumber(), // percent
                unLockDate: new BigNumber(dt?.unLockDate?.toString()).toNumber(),
                totalLtdStaked: new BigNumber(resPoolStaked[index]?.toString()).dividedBy(10 ** tokenDecimals).toString()
            }
        })
        return {
            isPause: resPaused[0] ?? false,
            total_v_ltd: new BigNumber(resTotalVLtd[0]?.toString()).dividedBy(10 ** tokenDecimals).toString(),
            pools: convertPools,
        }
    } catch (error) {
        console.log("Can not fetch public data", error)
        return {
            isPause: false,
            total_v_ltd: "0",
            pools: []
        }
    }
}

const fetchUserStaked = async (account: string, tokenDecimal: number, poolAddress: string): Promise<{ userStaked: UserStakedItem[] }> => {
    if (!account) {
        return {
            userStaked: []
        }
    }
    try {
        const calls = [...Array(DEFAULT_V_LTD_POOL)].map((dt, index) => {
            return {
                address: poolAddress,
                name: 'userStake',
                params: [account, index + 1]
            }
        })
        const resUserStaked = await multicall(sharesAbi, calls);
        const convertUserStaked = resUserStaked.map((dt, index) => {
            return {
                poolId: index + 1,
                amount: new BigNumber(dt?.amount?.toString()).dividedBy(10 ** tokenDecimal).toString(),
                stakedDate: new BigNumber(dt?.stakedDate?.toString()).toNumber(),
            }
        })
        return {
            userStaked: convertUserStaked
        }
    } catch (error) {
        console.log("Can not fetch user stake", error)
        return {
            userStaked: []
        }
    }
}