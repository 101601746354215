import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 10 10" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.48294 1.53017e-07C4.34634 1.47221e-07 4.21534 0.0526787 4.11875 0.146447C4.02216 0.240215 3.9679 0.367392 3.9679 0.5L3.96789 6.293L1.75731 4.146C1.66059 4.05211 1.52942 3.99937 1.39265 3.99937C1.25588 3.99937 1.12471 4.05211 1.028 4.146C0.931285 4.23989 0.876953 4.36722 0.876953 4.5C0.876953 4.63278 0.931285 4.76011 1.028 4.854L4.11829 7.854C4.16613 7.90056 4.22297 7.93751 4.28554 7.96271C4.34812 7.98792 4.4152 8.00089 4.48294 8.00089C4.55069 8.00089 4.61777 7.98792 4.68034 7.96271C4.74292 7.93751 4.79975 7.90056 4.8476 7.854L7.93789 4.854C8.0346 4.76011 8.08893 4.63278 8.08893 4.5C8.08893 4.36722 8.0346 4.23989 7.93789 4.146C7.84118 4.05211 7.71001 3.99937 7.57323 3.99937C7.43646 3.99937 7.30529 4.05211 7.20858 4.146L4.99799 6.293L4.99799 0.5C4.99799 0.367392 4.94373 0.240215 4.84714 0.146447C4.75055 0.0526787 4.61954 1.58814e-07 4.48294 1.53017e-07Z" fill="url(#paint0_linear_974:177331)"/>
        <defs>
        <linearGradient id="paint0_linear_974:177331" x1="0.876953" y1="1.31594" x2="2.34955" y2="8.23513" gradientUnits="userSpaceOnUse">
        <stop offset="0.0146047" stopColor="#E7D785"/>
        <stop offset="0.176444" stopColor="#E7D785"/>
        <stop offset="0.353531" stopColor="#D9AF5C"/>
        <stop offset="0.499367" stopColor="#E7D785"/>
        <stop offset="0.645203" stopColor="#D0B568"/>
        <stop offset="0.791039" stopColor="#D2B267"/>
        <stop offset="0.968125" stopColor="#E9DC87"/>
        </linearGradient>
        </defs>
    </Svg>
  );
};

export default Icon;
