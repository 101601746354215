import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router';
import { Button, Text, LogoIcon, Flex } from '@itdeltalabs/livetrade-uikit'
import PageFullWidth from 'components/Layout/PageFullWidth'
import { useTranslation } from 'contexts/Localization'
import Container from 'components/Layout/Container'
import useTheme from 'hooks/useTheme'

const StyledComingSoon = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;
`

const StyledTextComingSoon = styled.div`
  color: ${({ theme }) => (theme.isDark ? '#fff' : '#000')};
  font-size: 4vh;
  margin-top: 1rem;
  margin-bottom: 1rem;
`
const CustomContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    padding-top:1rem;
    flex-direction: column;
    overflow: hidden;
    @media only screen and (max-width: 600px) {
      padding-left:10px;
      padding-right:10px;
    }
`
const Img = styled.img`
  width: 1000px;
  height:auto;
  z-index: 10;
`
const Mask1 = styled.img`
  width:auto;
  height:100%;
  position: absolute;
  left: -5%;
  top: 0%;
  bottom:0%;
  transform: rotate(6.52deg);
  @media only screen and (min-width: 1000px) and (max-width: 1300px) {
    left: -2%;
    top: 10%;
    bottom:0%;
    width:500px;
    height:auto;
  }
  @media only screen and (min-width: 600px) and (max-width: 999px) {
    left: -5%;
    top: 20%;
    bottom:0%;
    width:400px;
    height:auto;
  }
  @media only screen and (max-width: 600px) {
    display:none;
  }
`
const Mask2 = styled.img`
  width:auto;
  height:100%;
  position: absolute;
  right: -1%;
  top: -5%;
  bottom: 0%;
  transform: rotate(10deg);
  @media only screen and (min-width: 1000px) and (max-width: 1300px) {
    right: -2%;
    top: -1%;
    bottom: 0%;
    width:600px;
    height:auto;
  }
  @media only screen and (min-width: 600px) and (max-width: 999px) {
    right: -2%;
    top: 5%;
    bottom: 0%;
    width:500px;
    height:auto;
  }
  @media only screen and (max-width: 600px) {
   display:none;
  }
`
const Heading = styled(Text)`
  font-size:56px;
  @media only screen and (max-width: 600px) {
    font-size:30px;
  }
`
const SubTitle = styled(Text)`
  @media only screen and (max-width: 600px) {
    font-size:13px;
  }
`
const ContainerSubTitle = styled(Flex)`
  width:100%;
  @media only screen and (max-width: 600px) {
    width:100%;
  }
  
`
const ComingSoon = () => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const history = useHistory();
  return (
    <PageFullWidth >
      <CustomContainer style={{position:"relative"}}>
          <Heading color='primaryBright' bold mt="1rem">Coming soon</Heading>
          <SubTitle color='text' mt="1rem">{t(`We will be up and coming soon with appealing features.`)}</SubTitle>
          <Button mt="1rem" onClick={()=> history.push("/")} style={{zIndex:20}}>{t("Back Home")}</Button>
          <Img src={theme.isDark ? "/images/ComingSoonDark.png" : "/images/ComingSoonLight.png"} alt="mask-comingsoon"/>
          <Mask1 src="/images/ComingsonMask1.png" alt="mask-background"/>
          <Mask2 src="/images/ComingsonMask2.png" alt="mask-background"/>
      </CustomContainer>
     
    </PageFullWidth>
  )
}


export default ComingSoon
