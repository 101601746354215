import useTheme from "hooks/useTheme";
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
    const { theme } = useTheme() 
  return (
    <Svg width="24" height="24" viewBox="0 0 14 14" fill="none" {...props} style={{fill:"none"}}>
<path d="M4.00195 2.80616V1.60449" stroke={theme.colors.text} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M9.99805 2.80616V1.60449" stroke={theme.colors.text}  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.5295 2.20508H3.47116C2.43866 2.20508 1.60449 3.03924 1.60449 4.07174V10.5351C1.60449 11.5676 2.43866 12.4017 3.47116 12.4017H10.5353C11.5678 12.4017 12.402 11.5676 12.402 10.5351V4.07174C12.3962 3.03924 11.562 2.20508 10.5295 2.20508Z" stroke={theme.colors.text} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M1.60449 4.6084H12.3962" stroke={theme.colors.text} strokeLinecap="round" strokeLinejoin="round"/>
<path d="M10.5003 7.00065C10.8225 7.00065 11.0837 6.73948 11.0837 6.41732C11.0837 6.09515 10.8225 5.83398 10.5003 5.83398C10.1782 5.83398 9.91699 6.09515 9.91699 6.41732C9.91699 6.73948 10.1782 7.00065 10.5003 7.00065Z" fill={theme.colors.text}/>
<path d="M8.16634 7.00065C8.48851 7.00065 8.74967 6.73948 8.74967 6.41732C8.74967 6.09515 8.48851 5.83398 8.16634 5.83398C7.84418 5.83398 7.58301 6.09515 7.58301 6.41732C7.58301 6.73948 7.84418 7.00065 8.16634 7.00065Z" fill={theme.colors.text}/>
<path d="M5.83333 7.00065C6.1555 7.00065 6.41667 6.73948 6.41667 6.41732C6.41667 6.09515 6.1555 5.83398 5.83333 5.83398C5.51117 5.83398 5.25 6.09515 5.25 6.41732C5.25 6.73948 5.51117 7.00065 5.83333 7.00065Z" fill={theme.colors.text}/>
<path d="M3.50033 7.00065C3.82249 7.00065 4.08366 6.73948 4.08366 6.41732C4.08366 6.09515 3.82249 5.83398 3.50033 5.83398C3.17816 5.83398 2.91699 6.09515 2.91699 6.41732C2.91699 6.73948 3.17816 7.00065 3.50033 7.00065Z" fill={theme.colors.text}/>
<path d="M10.5003 9.03581C10.8225 9.03581 11.0837 8.77464 11.0837 8.45247C11.0837 8.13031 10.8225 7.86914 10.5003 7.86914C10.1782 7.86914 9.91699 8.13031 9.91699 8.45247C9.91699 8.77464 10.1782 9.03581 10.5003 9.03581Z" fill={theme.colors.text}/>
<path d="M8.16634 9.03581C8.48851 9.03581 8.74967 8.77464 8.74967 8.45247C8.74967 8.13031 8.48851 7.86914 8.16634 7.86914C7.84418 7.86914 7.58301 8.13031 7.58301 8.45247C7.58301 8.77464 7.84418 9.03581 8.16634 9.03581Z" fill={theme.colors.text}/>
<path d="M5.83333 9.03581C6.1555 9.03581 6.41667 8.77464 6.41667 8.45247C6.41667 8.13031 6.1555 7.86914 5.83333 7.86914C5.51117 7.86914 5.25 8.13031 5.25 8.45247C5.25 8.77464 5.51117 9.03581 5.83333 9.03581Z" fill={theme.colors.text}/>
<path d="M3.50033 9.03581C3.82249 9.03581 4.08366 8.77464 4.08366 8.45247C4.08366 8.13031 3.82249 7.86914 3.50033 7.86914C3.17816 7.86914 2.91699 8.13031 2.91699 8.45247C2.91699 8.77464 3.17816 9.03581 3.50033 9.03581Z" fill={theme.colors.text}/>
<path d="M8.16634 11.0651C8.48851 11.0651 8.74967 10.8039 8.74967 10.4818C8.74967 10.1596 8.48851 9.89844 8.16634 9.89844C7.84418 9.89844 7.58301 10.1596 7.58301 10.4818C7.58301 10.8039 7.84418 11.0651 8.16634 11.0651Z" fill={theme.colors.text}/>
<path d="M5.83333 11.0651C6.1555 11.0651 6.41667 10.8039 6.41667 10.4818C6.41667 10.1596 6.1555 9.89844 5.83333 9.89844C5.51117 9.89844 5.25 10.1596 5.25 10.4818C5.25 10.8039 5.51117 11.0651 5.83333 11.0651Z" fill={theme.colors.text}/>
<path d="M3.50033 11.0651C3.82249 11.0651 4.08366 10.8039 4.08366 10.4818C4.08366 10.1596 3.82249 9.89844 3.50033 9.89844C3.17816 9.89844 2.91699 10.1596 2.91699 10.4818C2.91699 10.8039 3.17816 11.0651 3.50033 11.0651Z" fill={theme.colors.text}/>

    </Svg>
  );
};

export default Icon;
