import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 25 25" {...props}>
<path d="M19.54 0H3.46C2.104 0 1 1.104 1 2.472V18.696C1 20.064 2.104 21.168 3.46 21.168H17.068L16.432 18.948L17.968 20.376L19.42 21.72L22 24V2.472C22 1.104 20.896 0 19.54 0ZM14.908 15.672C14.908 15.672 14.476 15.156 14.116 14.7C15.688 14.256 16.288 13.272 16.288 13.272C15.796 13.596 15.328 13.824 14.908 13.98C14.308 14.232 13.732 14.4 13.168 14.496C12.016 14.712 10.96 14.652 10.06 14.484C9.376 14.352 8.788 14.16 8.296 13.968C8.02 13.86 7.72 13.728 7.42 13.56C7.384 13.536 7.348 13.524 7.312 13.5C7.288 13.488 7.276 13.476 7.264 13.464C7.048 13.344 6.928 13.26 6.928 13.26C6.928 13.26 7.504 14.22 9.028 14.676C8.668 15.132 8.224 15.672 8.224 15.672C5.572 15.588 4.564 13.848 4.564 13.848C4.564 9.984 6.292 6.852 6.292 6.852C8.02 5.556 9.664 5.592 9.664 5.592L9.784 5.736C7.624 6.36 6.628 7.308 6.628 7.308C6.628 7.308 6.892 7.164 7.336 6.96C8.62 6.396 9.64 6.24 10.06 6.204C10.132 6.192 10.192 6.18 10.264 6.18C10.996 6.084 11.824 6.06 12.688 6.156C13.828 6.288 15.052 6.624 16.3 7.308C16.3 7.308 15.352 6.408 13.312 5.784L13.48 5.592C13.48 5.592 15.124 5.556 16.852 6.852C16.852 6.852 18.58 9.984 18.58 13.848C18.58 13.848 17.56 15.588 14.908 15.672ZM9.328 10.068C8.644 10.068 8.104 10.668 8.104 11.4C8.104 12.132 8.656 12.732 9.328 12.732C10.012 12.732 10.552 12.132 10.552 11.4C10.564 10.668 10.012 10.068 9.328 10.068ZM13.708 10.068C13.024 10.068 12.484 10.668 12.484 11.4C12.484 12.132 13.036 12.732 13.708 12.732C14.392 12.732 14.932 12.132 14.932 11.4C14.932 10.668 14.392 10.068 13.708 10.068Z"/>
    </Svg>
  );
};

export default Icon;
