import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 27 23" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M26.25 11.4998C26.25 11.0771 26.0821 10.6717 25.7832 10.3729C25.4843 10.074 25.0789 9.90606 24.6562 9.90606H6.19106L13.0346 3.06569C13.3339 2.76643 13.502 2.36054 13.502 1.93732C13.502 1.51409 13.3339 1.1082 13.0346 0.808941C12.7354 0.509678 12.3295 0.341553 11.9062 0.341553C11.483 0.341553 11.0771 0.509678 10.7779 0.808941L1.21537 10.3714C1.06695 10.5195 0.949198 10.6954 0.868853 10.889C0.788507 11.0826 0.74715 11.2902 0.74715 11.4998C0.74715 11.7094 0.788507 11.917 0.868853 12.1106C0.949198 12.3043 1.06695 12.4801 1.21537 12.6282L10.7779 22.1907C11.0771 22.49 11.483 22.6581 11.9062 22.6581C12.3295 22.6581 12.7354 22.49 13.0346 22.1907C13.3339 21.8914 13.502 21.4855 13.502 21.0623C13.502 20.6391 13.3339 20.2332 13.0346 19.9339L6.19106 13.0936H24.6562C25.0789 13.0936 25.4843 12.9257 25.7832 12.6268C26.0821 12.3279 26.25 11.9225 26.25 11.4998Z"/>
    </Svg>
  );
};

export default Icon;
