import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 25 25" {...props}>
            <path d="M21.368 3.125H3.63359L3.63281 3.12734L12.4992 20.1797H12.5023L21.3695 3.12813L21.368 3.125ZM11.6211 4.6875H13.3789V11.7188H11.6211V4.6875ZM12.5008 15.625C12.269 15.625 12.0424 15.5563 11.8497 15.4275C11.657 15.2987 11.5068 15.1157 11.4181 14.9016C11.3294 14.6874 11.3062 14.4518 11.3514 14.2245C11.3966 13.9972 11.5082 13.7884 11.6721 13.6245C11.836 13.4606 12.0448 13.349 12.2722 13.3038C12.4995 13.2585 12.7351 13.2818 12.9492 13.3705C13.1634 13.4592 13.3464 13.6094 13.4752 13.8021C13.6039 13.9948 13.6727 14.2213 13.6727 14.4531C13.6727 14.7639 13.5492 15.062 13.3294 15.2818C13.1097 15.5015 12.8116 15.625 12.5008 15.625Z" fill="#FDB32F"/>
            <path d="M23.3239 1.93828C23.3934 2.05304 23.4322 2.18374 23.4366 2.31782C23.4409 2.45191 23.4108 2.58486 23.3489 2.70391L13.1927 22.2352C13.1267 22.3619 13.0272 22.4682 12.905 22.5423C12.7828 22.6164 12.6426 22.6556 12.4997 22.6556C12.3568 22.6556 12.2166 22.6164 12.0944 22.5423C11.9722 22.4682 11.8727 22.3619 11.8067 22.2352L1.65047 2.70391C1.58862 2.58485 1.55847 2.45186 1.56293 2.31777C1.56739 2.18368 1.60631 2.053 1.67594 1.93831C1.74557 1.82362 1.84356 1.7288 1.96047 1.66298C2.07738 1.59716 2.20927 1.56255 2.34344 1.5625H22.6559C22.7902 1.56247 22.9222 1.59703 23.0392 1.66285C23.1562 1.72867 23.2542 1.82353 23.3239 1.93828ZM3.63172 3.12734L12.4981 20.1797H12.5012L21.3684 3.12813L21.3653 3.125H3.63328L3.63172 3.12734Z" fill="#FDB32F"/>
    </Svg>
  );
};

export default Icon;
