import { CardViewIcon, ListViewIcon } from 'components/Pancake-uikit'
import React from 'react'
import { IconButton } from '@itdeltalabs/livetrade-uikit'
import styled from 'styled-components'
import { ViewMode } from '../types'


interface ToggleViewProps {
  viewMode: ViewMode
  onToggle: (mode: ViewMode) => void
}

const Container = styled.div`
  border:none;
  background:${({ theme }) => theme.colors.input};
  box-shadow: inset 0px -2px 4px rgba(255, 255, 255, 0.16), inset 4px 3px 3px rgba(0, 0, 0, 0.28);
  border-radius: 5px;
  justify-content: center;
  padding:3px 7px 0px 7px!important;
  ${({ theme }) => theme.mediaQueries.sm} {
    padding:0px !important;
  }
`
const filterActive = {
  filter: "invert(100%) sepia(0%) saturate(7470%) hue-rotate(122deg) brightness(93%) contrast(109%)"
}

const filterInactive = {
  filter: "invert(38%) sepia(13%) saturate(300%) hue-rotate(346deg) brightness(101%) contrast(92%)"
}
const ToggleView: React.FunctionComponent<ToggleViewProps> = ({ viewMode, onToggle }) => {
  const handleToggle = (mode: ViewMode) => {
    if (viewMode !== mode) {
      onToggle(mode)
    }
  }

  return (
    <Container>
      <IconButton variant={viewMode === 'TABLE' ? "primary" : "text" } scale="sm" onClick={() => handleToggle(ViewMode.TABLE)}>
        <ListViewIcon style={viewMode === 'TABLE' ? filterActive : filterInactive} />
      </IconButton>
      <IconButton variant={viewMode === 'CARD' ? "primary" : "text" } scale="sm" onClick={() => handleToggle(ViewMode.CARD)}>
        <CardViewIcon style={viewMode === 'CARD' ? filterActive : filterInactive} />
      </IconButton>
    </Container>
  )
}

export default ToggleView
