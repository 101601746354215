import { useMemo } from 'react'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import {
    getBep20Contract,
    getCakeContract,
    getBunnyFactoryContract,
    getBunnySpecialContract,
    getPancakeRabbitContract,
    getProfileContract,
    getIfoV1Contract,
    getIfoV2Contract,
    getMasterchefContract,
    getPointCenterIfoContract,
    getSouschefContract,
    getClaimRefundContract,
    getTradingCompetitionContract,
    getEasterNftContract,
    getErc721Contract,
    getCakeVaultContract,
    getPredictionsContract,
    getChainlinkOracleContract,
    getSouschefV2Contract,
    getLotteryV2Contract,
    getBunnySpecialCakeVaultContract,
    getBunnySpecialPredictionContract,
    getFarmAuctionContract,
    getBunnySpecialLotteryContract,
    getAirdropContract,
    getBabysharkContract,
    getPoolNftContract,
    getMemberOfKSharkContract,
    getBountyContract,
    getIdoStaking,
    getDipo,
    getprivateShareIDO,
    getPrivateShareStaking
} from 'utils/contractHelpers'
import { getMulticallAddress } from 'utils/addressHelpers'

// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts'
import { ChainId, WETH } from '@pancakeswap/sdk'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import refCodeAbi from 'config/abi/ref_code_abi.json';
import ENS_PUBLIC_RESOLVER_ABI from '../config/abi/ens-public-resolver.json'
import ENS_ABI from '../config/abi/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../config/abi/erc20'
import ERC20_ABI from '../config/abi/erc20.json'
import WETH_ABI from '../config/abi/weth.json'
import multiCallAbi from '../config/abi/Multicall.json'
import migrationAbi from '../config/abi/LTDMigration.json'
import ltdAirdropAbi from "../config/abi/ltdAirdropAbi.json"
import marketplaceAbi from '../config/abi/LTDMarketplace.json'
import RunTogetherBox from "../config/abi/RunTogetherBox.json"
import RunMarketplace from "../config/abi/RunMarketplace.json"
import sharesAbi from "../config/abi/shares_abi.json"
import poolV2Abi from "../config/abi/poolV2.json"
import { getContract } from '../utils'




/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useIfoV1Contract = (address: string) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getIfoV1Contract(address, library.getSigner()), [address, library])
}

export const useIfoV2Contract = (address: string) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getIfoV2Contract(address, library.getSigner()), [address, library])
}

export const useERC20 = (address: string) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBep20Contract(address, library.getSigner()), [address, library])
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address: string) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getErc721Contract(address, library.getSigner()), [address, library])
}

export const useCake = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getCakeContract(library.getSigner()), [library])
}

export const useBabyShark = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBabysharkContract(library.getSigner()), [library])
}

export const useBunnyFactory = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBunnyFactoryContract(library.getSigner()), [library])
}

export const usePancakeRabbits = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getPancakeRabbitContract(library.getSigner()), [library])
}

export const useProfile = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getProfileContract(library.getSigner()), [library])
}

export const useLotteryV2Contract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getLotteryV2Contract(library.getSigner()), [library])
}

export const useMasterchef = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMasterchefContract(library.getSigner()), [library])
}

export const useSousChef = (id) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getSouschefContract(id, library.getSigner()), [id, library])
}

export const usePoolNft = (id) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getPoolNftContract(id, library.getSigner()), [id, library])
}

export const useSousChefV2 = (id) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getSouschefV2Contract(id, library.getSigner()), [id, library])
}

export const usePointCenterIfoContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getPointCenterIfoContract(library.getSigner()), [library])
}

export const useBunnySpecialContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBunnySpecialContract(library.getSigner()), [library])
}

export const useClaimRefundContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getClaimRefundContract(library.getSigner()), [library])
}

export const useTradingCompetitionContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getTradingCompetitionContract(library.getSigner()), [library])
}

export const useEasterNftContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getEasterNftContract(library.getSigner()), [library])
}

export const useCakeVaultContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getCakeVaultContract(library.getSigner()), [library])
}

export const usePredictionsContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getPredictionsContract(library.getSigner()), [library])
}

export const useChainlinkOracleContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getChainlinkOracleContract(library.getSigner()), [library])
}

export const useSpecialBunnyCakeVaultContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBunnySpecialCakeVaultContract(library.getSigner()), [library])
}

export const useSpecialBunnyPredictionContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBunnySpecialPredictionContract(library.getSigner()), [library])
}

export const useBunnySpecialLotteryContract = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBunnySpecialLotteryContract(library.getSigner()), [library])
}

export const useAirdrop = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getAirdropContract(library.getSigner()), [library])
}

export const useMemberOfKShark = () => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getMemberOfKSharkContract(library.getSigner()), [library])
}

export const useBounty = (title) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getBountyContract(title, library.getSigner()), [title, library])
}

export const useIdoStakingHandle = (title) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getIdoStaking(title, library.getSigner()), [title, library])
}

export const useDipo = (title) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getDipo(title, library.getSigner()), [title, library])
}
export const useIdoHandle = (title) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getprivateShareIDO(title, library.getSigner()), [title, library])
}
export const usePrivateShareStakingHandle = (title) => {
    const { library } = useActiveWeb3React()
    return useMemo(() => getPrivateShareStaking(title, library.getSigner()), [title, library])
}

export const useFarmAuctionContract = () => {
    const { account, library } = useActiveWeb3React()
    // This hook is slightly different from others
    // Calls were failing if unconnected user goes to farm auction page
    // Using library instead of library.getSigner() fixes the problem for unconnected users
    // However, this fix is not ideal, it currently has following behavior:
    // - If you visit Farm Auction page coming from some other page there are no errors in console (unconnected or connected)
    // - If you go directly to Farm Auction page
    //   - as unconnected user you don't see any console errors
    //   - as connected user you see `unknown account #0 (operation="getAddress", code=UNSUPPORTED_OPERATION, ...` errors
    //     the functionality of the page is not affected, data is loading fine and you can interact with the contract
    //
    // Similar behavior was also noticed on Trading Competition page.
    return useMemo(() => getFarmAuctionContract(account ? library.getSigner() : library), [library, account])
}

// Code below migrated from Exchange useContract.ts

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
    const { library, account } = useActiveWeb3React()

    return useMemo(() => {
        if (!address || !ABI || !library) return null
        try {
            return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined)
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [address, ABI, library, withSignerIfPossible, account])
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
    const { chainId } = useActiveWeb3React()
    return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible)
}


export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
    const { chainId } = useActiveWeb3React()
    let address: string | undefined
    if (chainId) {
        // eslint-disable-next-line default-case
        switch (chainId) {
            case ChainId.MAINNET:
            case ChainId.TESTNET:
                address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
                break
        }
    }
    return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
    return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMigrationContract(migrationAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(migrationAddress, migrationAbi, withSignerIfPossible)
}
export function useAirdopContract(AirdropAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(AirdropAddress, ltdAirdropAbi, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
    return useContract(getMulticallAddress(), multiCallAbi, false)
}
export function useMarketplaceContract(marketplaceAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(marketplaceAddress, marketplaceAbi, withSignerIfPossible)
}
export function useRunTogether(RunAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(RunAddress, RunTogetherBox, withSignerIfPossible)
}
export function useRunMarketplaceContract(runMarketplaceAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(runMarketplaceAddress, RunMarketplace, withSignerIfPossible)
}

export function usePoolV2Contract(poolV2Address?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(poolV2Address, poolV2Abi, withSignerIfPossible)
}

export function useSharesContract(poolAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(poolAddress, sharesAbi, withSignerIfPossible)
}

export function useRefCodeContract(poolAddress?: string, withSignerIfPossible?: boolean): Contract | null {
    return useContract(poolAddress, refCodeAbi, withSignerIfPossible)
}