import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@itdeltalabs/livetrade-uikit/dist/theme'

declare module 'styled-components' {
    /* eslint-disable @typescript-eslint/no-empty-interface */
    export interface DefaultTheme extends PancakeTheme { }
}

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  .container-table-acquisition .ant-table-thead .ant-table-cell::before {
        display: none;
    }
    .container-table-acquisition .ant-table-tbody >tr >td {
        border-bottom: none;
        vertical-align: middle;
    }
    .container-table-acquisition .ant-table-thead .ant-table-cell {
        border-bottom: none;
        vertical-align: middle;
    }
    .container-table-acquisition .ant-table-thead >tr >th {
        border-bottom: none;
        padding: 23px 16px;
    }
    .container-table-acquisition .ant-pagination-item-link {
        border: 1px solid ${({ theme }) => theme.isDark ? "white" : "#8687873D"};
        border-radius: 5px !important;
    }
    .container-table-acquisition .ant-pagination-item {
        border: 1px solid ${({ theme }) => theme.isDark ? "white" : "#8687873D"};
        border-radius: 5px !important;
    }
    .container-table-acquisition .ant-pagination-item-active {
        border: none !important;
    }
    .container-row-active .ant-table-cell {
        background: ${({ theme }) => theme.isDark ? "#011226" : "white"} !important;
    }
    .container-table-acquisition .ant-table-pagination {
        background: ${({ theme }) => theme.isDark ? "#182636" : "#f5f5f5"} !important;
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 20px 24px;
        margin: 0 !important;
        border-bottom-left-radius: 8px 8px;
        border-bottom-right-radius: 8px 8px;
    }
    .ant-switch {
        background: ${({ theme }) => theme.isDark ? "#182636" : "#f5f5f5"};
    }
    .ant-switch:hover:not(.ant-switch-disabled) {
        background: ${({ theme }) => theme.isDark ? "#182636" : "#f5f5f5"};
    }
    .ant-btn-primary.disabled{
        opacity: 0.65 !important;
        background: #1677ff !important;
        border-color: #1677ff !important;
        color: #fff !important;
        box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1) !important;
    }
`

export default GlobalStyle
