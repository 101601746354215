import axios from "axios";
import BigNumber from "bignumber.js";
import ERC20_ABI from 'config/abi/erc20.json';
import PoolV2Abi from "config/abi/poolV2.json";
import { PoolV2Config } from "config/constants/pool_v2";
import { getAddress } from "utils/addressHelpers";
import multicall from "utils/multicall";
import { PoolItem } from "./type";


export const getDataPool = async (account: string, poolConfig: PoolV2Config[]): Promise<{ pools: PoolItem[] }> => {
    const [ balances, prices, allowances, tvl, useStaked, pauseds] = await Promise.all([
        fetchBalance(account, poolConfig),
        fetchTokenPrice(poolConfig),
        fetchAllowance(account, poolConfig),
        fetchTVL(poolConfig),
        fetchListStaked(account, poolConfig),
        fetchPause(poolConfig)
    ])
    
    const mergeData = poolConfig.map((dt, index) => {
        const balance = balances?.find((it) => it?.id === dt?.id )
        const allowance = allowances?.find((it) => it?.id === dt?.id )
        const price = prices?.find((it) => it?.id === dt?.id )
        const totalStaked = tvl?.find((it) => it?.id === dt?.id )
        const user = useStaked?.find((it) => it?.id === dt?.id )
        const paused = pauseds?.find((it) => it?.id === dt?.id )
        return {
            ...dt,
            stakingBalance: balance.stakingBalance,
            stakingAllowance: allowance?.stakingAllowance,
            stakingPriceUSD: price?.stakingPriceUSD,
            earnPriceUSD: price?.earnPriceUSD,
            tvl: totalStaked?.tvl,
            userStaked: user.userStaked,
            paused: paused?.paused
        }
    })
    return {
        pools: mergeData
    }
};

const fetchAllowance = async (account: string, poolConfig: PoolV2Config[]) => {
    try {
        const calls = poolConfig.map((dt) => {
            const tokenAddress = getAddress(dt?.stakingToken.address)
            const stakingAddress = getAddress(dt?.contractAddress)
            return {
                address: tokenAddress,
                name: 'allowance',
                params: [account, stakingAddress]
            }
        })
        const resultAllowance = await multicall(ERC20_ABI, calls);
        const mergeData = resultAllowance?.map((dt, index) => {
            return {
                id: poolConfig[index]?.id,
                stakingAllowance: new BigNumber(dt?.toString()).toString()
            }
        })
        return mergeData
    } catch (error) {
        const mergeData = poolConfig?.map((dt, index) => {
            return {
                id: dt?.id,
                stakingAllowance: '0'
            }
        })
        return mergeData
    }
    
}

const fetchTokenPrice = async (poolConfig: PoolV2Config[]) => {
    try {
        const response = await axios.get('https://exchange.goonus.io/exchange/api/v1/showup-prices');
        const mergeData = poolConfig?.map((dt) => {
            const STAKE = dt?.stakeTokenPriceSymbol
            const EARN = dt?.earnTokenPriceSymbol
            return {
                id: dt?.id,
                stakingPriceUSD: response?.data?.[STAKE]?.bid,
                earnPriceUSD: response?.data?.[EARN]?.bid,
            }
        })
        return mergeData
    } catch (error) {
        console.log("can not get price", error)
        const mergeData = poolConfig?.map((dt) => {
            return {
                id: dt?.id,
                stakingPriceUSD: "0",
                earnPriceUSD: "0"
            }
        })
        return mergeData
    }
}


const fetchBalance = async (account: string, poolConfig: PoolV2Config[]) => {
    if (account?.length > 0) {
        try {
            const calls = poolConfig.map((dt) => {
                const tokenAddress = getAddress(dt?.stakingToken.address)
                return {
                    address: tokenAddress,
                    name: 'balanceOf',
                    params: [account]
                }
            })
            const resultBalance = await multicall(ERC20_ABI, calls);
            const mergeData = resultBalance?.map((dt, index) => {
                return {
                    id: poolConfig[index]?.id,
                    stakingBalance: new BigNumber(dt?.balance?.toString()).toString()
                }
            })
            return mergeData
        } catch (error) {
            console.log("can not fetch balance", error)
            const mergeData = poolConfig?.map((dt) => {
                return {
                    id: dt?.id,
                    stakingBalance: "0"
                }
            })
            return mergeData
        }
    } else {
        const mergeData = poolConfig?.map((dt) => {
            return {
                id: dt?.id,
                stakingBalance: "0"
            }
        })
        return mergeData
    }

}


const fetchTVL = async (poolConfig: PoolV2Config[]) => {
    try {
        const calls = poolConfig.map((dt) => {
            const stakingAddress = getAddress(dt?.contractAddress)
            return {
                address: stakingAddress,
                name: 'totalLTDStaking',
                params: []
            }
        })
        const resultStaking = await multicall(PoolV2Abi, calls);
        const mergeData = resultStaking?.map((dt, index) => {
            return {
                id: poolConfig[index]?.id,
                tvl: new BigNumber(dt?.toString()).toString()
            }
        })
        return mergeData
    } catch (error) {
        const mergeData = poolConfig?.map((dt) => {
            return {
                id: dt?.id,
                tvl: '0'
            }
        })
        return mergeData
    }
    
}

const fetchListStaked = async (account: string, poolConfig: PoolV2Config[]) => {
    if (account?.length > 0) {
        try {
            const calls = poolConfig.map((dt) => {
                const contractAddress = getAddress(dt?.contractAddress)
                return {
                    address: contractAddress,
                    name: 'getListStaked',
                    params: [account]
                }
            })
            const [resultStaked] = await multicall(PoolV2Abi, calls);
            const mergeData = resultStaked?.map((dt, index) => {
                const mergeUserStaked = dt?.map((it, key) => {
                    return {
                        stakedDate: new BigNumber(it?.stakedDate?.toString()).multipliedBy(1000).toNumber(),
                        claimedDate: new BigNumber(it?.claimedDate?.toString()).multipliedBy(1000).toNumber(),
                        amount: new BigNumber(it?.amount?.toString())?.toString(),
                        index: key
                    }
                })
                return {
                    id: poolConfig[index]?.id,
                    userStaked: mergeUserStaked
                    
                }
            })
            return mergeData
        } catch (error) {
            console.log("can not fetch balance", error)
            const mergeData = poolConfig?.map((dt) => {
                return {
                    id: dt?.id,
                    userStaked: []
                }
            })
            return mergeData
        }
    } else {
        const mergeData = poolConfig?.map((dt) => {
            return {
                id: dt?.id,
                userStaked: []
            }
        })
        return mergeData
    }

}


const fetchPause = async (poolConfig: PoolV2Config[]) => {
    try {
        const calls = poolConfig.map((dt) => {
            const stakingAddress = getAddress(dt?.contractAddress)
            return {
                address: stakingAddress,
                name: 'paused',
                params: []
            }
        })
        const resultPaused = await multicall(PoolV2Abi, calls);
        const mergeData = resultPaused?.map((dt, index) => {
            return {
                id: poolConfig[index]?.id,
                paused: dt[0]
            }
        })
        return mergeData
    } catch (error) {
        const mergeData = poolConfig?.map((dt) => {
            return {
                id: dt?.id,
                paused: false
            }
        })
        return mergeData
    }
    
}
