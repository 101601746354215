import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { fetchTransactionHash, fetchPoolData, fetchActivePoolId } from './actions';
import { PoolItem, PoolV2Type } from './type';


const initialState: PoolV2Type = {
    transactionHash: "",
    pools: [],
    poolId: null
};

export const poolV2Reducer = createReducer(initialState, (builder) => {
    builder
        .addCase(fetchTransactionHash, (state: PoolV2Type, action: PayloadAction<{transactionHash: string}>) => {
            const data = action.payload;
            state.transactionHash = data.transactionHash;
        })
        .addCase(fetchPoolData, (state: PoolV2Type, action: PayloadAction<{ pools: PoolItem[] }>) => {
            const data = action.payload;
            state.pools = data?.pools
        })
        .addCase(fetchActivePoolId, (state: PoolV2Type, action: PayloadAction<{poolId: number}>) => {
            const data = action.payload;
            state.poolId = data.poolId
        })
})