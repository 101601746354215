import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 15 15" {...props}>
        <path d="M3.42906 7.19695H2.19531L5.6493 12.8044L14.5309 0.46875H13.2972L5.6493 8.87906L3.42906 7.19695Z" fill="#49A2F2"/>
        <path d="M12.3108 12.8044C12.3108 12.9406 12.2004 13.051 12.064 13.051H2.19563C2.13021 13.051 2.06747 13.025 2.0212 12.9788C1.97492 12.9326 1.94889 12.8699 1.94883 12.8044V2.93585C1.94883 2.79944 2.05945 2.68905 2.19563 2.68905H8.94422L10.2905 1.20874H0.962109C0.831279 1.20893 0.705864 1.261 0.613374 1.35353C0.520885 1.44606 0.468874 1.5715 0.46875 1.70233V14.0377C0.46875 14.3108 0.689766 14.5313 0.962109 14.5313H13.2975C13.3623 14.5314 13.4265 14.5187 13.4864 14.4939C13.5463 14.4691 13.6007 14.4328 13.6465 14.387C13.6923 14.3411 13.7287 14.2867 13.7534 14.2268C13.7782 14.1669 13.7909 14.1028 13.7909 14.038V4.45226L12.3108 6.50843V12.8044Z"/>
    </Svg>
  );
};

export default Icon;
