import { Token } from "config/constants/types";

/* eslint-disable camelcase */
export const ACTIVE_SCREEN = {
    MAIN: "main",
    ACQUISITION_DETAIL: "acquisition_detail",
    HISTORY_DETAIL: "history_detail",
    PROPOSE_DETAIL: 'propose_detail'
} as const;

export const ACTIVE_TAB = {
    INFO: "info",
    RESULT: "result"
} as const;

export type Variant = typeof ACTIVE_SCREEN[keyof typeof ACTIVE_SCREEN];
export type ActiveTabVariant = typeof ACTIVE_TAB[keyof typeof ACTIVE_TAB];


export interface InitialState {
    isSeeAllAcquisition: boolean,
    isSeeAllHistory: boolean
    dialogTransactionDetails: BuybacksType | null,
    dialogWithdraw: {
        pool: PoolItem,
    },
    dialogDeposit: {
        pool: PoolItem,
    },
    activeScreen: Variant,
    historyDetail: {
        buybacks: string[],
        cycleId: number;
        endDate: string;
        isPublish: boolean;
        startDate: string;
        totalReward: number;
        _id: string;
    },
    proposeDetail: any,
    shares: Shares,
    transactionHash: string
    isLoading: boolean,
    dataHistoryProfitSharing: DataHistoryProfitSharingType;
    dataOneLiveTradeShares: {
        data: ItemsHistoryProfitSharingType,
        isLoading: boolean,
    }
}

export interface DataHistoryProfitSharingType {
    items: ItemsHistoryProfitSharingType[];
    total: number;
    isLoading: boolean;
}

export interface ItemsHistoryProfitSharingType {
    _id: string;
    startDate: string;
    endDate: string;
    totalReward: number;
    isPublish: boolean;
    buybacks: BuybacksType[];
    cycleId: number;
    totalVLTD?: number;
    __v: number;
}

export interface ClaimHistoryType {
    _id: string;
    cycle: number;
    user: string;
    amount: number;
    commission: number;
}

export interface BuybacksType {
    _id: string;
    cycleId: string;
    datetime: string;
    amount: number;
    rate: number;

}

export interface AcquisitionType {
    tag: string,
    name: string,
    totalLTD: number,
    date: number,
    totalVNDC: number
}

export interface DataUser {
    balanceOf: string,
    allownace: string
}

export interface PoolItem {
    poolId: number,
    rating: number, // percent
    unLockDate: number,
    totalLtdStaked: string
}

export interface UserStakedItem {
    poolId: number,
    amount: string,
    stakedDate: number,
}

export interface Shares {
    dataUser: DataUser,
    isPause: boolean,
    total_v_ltd: string,
    pools: PoolItem[],
    userStaked: UserStakedItem[],
    token: Token,
    poolAddress: string
}