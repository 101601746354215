import React from "react";
import styled from 'styled-components';
import { SvgProps } from "../../../components/Svg/types";

const StyleDiv = styled.div`
  padding-right:1px
`

const Icon: React.FC<SvgProps> = (_props) => {
  return (
    <StyleDiv>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {..._props}>
        <g>
          <g>
            <path d="M363.639,262.267c-0.883-3.016-3.15-5.479-6.163-6.581c-0.608-0.222-5.537-1.966-13.193-3.086
              c32.708-34.803,21.909-75.464,19.356-83.405c-0.883-3.017-3.15-5.48-6.163-6.582c-1.404-0.514-25.885-9.146-53.777,0.561
              c1.575-6.461,2.496-13.564,2.496-21.358c0-49.867-37.763-71.785-45.341-75.676c-2.757-1.51-6.104-1.648-9.012-0.296
              c-1.875,0.871-45.929,21.954-45.929,75.972c0,7.81,0.925,14.928,2.506,21.399c-27.941-9.772-52.488-1.117-53.894-0.602
              c-3.013,1.102-5.28,3.565-6.163,6.582c-2.553,7.941-13.352,48.602,19.356,83.405c-7.656,1.12-12.585,2.864-13.193,3.086
              c-3.013,1.102-5.281,3.566-6.164,6.582c-2.606,8.107-13.812,50.311,21.45,85.573c20.406,20.406,43.054,25.274,60.022,25.274
              c6.282,0,11.773-0.669,16.096-1.463v65.433c0,5.522,4.477,10,10,10c5.523,0,10-4.478,10-10v-65.458
              c4.343,0.804,9.888,1.487,16.238,1.487c16.967,0,39.618-4.87,60.022-25.274C377.451,312.577,366.245,270.374,363.639,262.267z
              M245.929,340.479c-0.222,4.539-0.859,8.354-1.468,11.097c-10.689,2.375-37.433,5.197-60.507-17.878
              c-22.727-22.725-20.345-49.026-17.829-60.502c6.721-1.461,19.836-3.089,34.356,1.623c0.956,0.358,1.909,0.696,2.857,1.011
              c7.69,2.916,15.674,7.676,23.241,15.242c14.419,14.419,18.725,30.269,19.35,42.735V340.479z M245.929,247.408
              c-0.222,4.539-0.859,8.354-1.468,11.096c-6.623,1.473-19.409,3.108-33.666-1.342c-1.445-0.557-2.882-1.064-4.309-1.523
              c-7.458-2.946-15.181-7.662-22.533-15.013c-22.726-22.726-20.344-49.026-17.828-60.501c10.679-2.322,37.498-5.08,60.454,17.876
              c0.146,0.146,0.278,0.293,0.421,0.438c0.877,0.984,1.757,1.925,2.636,2.829c11.969,13.511,15.715,27.937,16.293,39.468V247.408z
              M256.07,197.234c-3.134-2.01-7.65-5.323-12.237-10.112c-0.468-0.513-0.941-1.026-1.427-1.537
              c-8.355-9.281-16.494-23.518-16.494-43.769c0-32.597,20.86-49.51,30.091-55.398c9.886,6.326,30.191,23.239,30.191,55.398
              c0,19.11-7.171,32.822-14.963,42.09c-1.62,1.622-3.136,3.26-4.565,4.906C262.668,192.699,258.839,195.47,256.07,197.234z
              M265.991,244.167c-0.014-11.74,3.023-27.276,15.457-41.866c1.473-1.464,2.954-3.039,4.422-4.732
              c22.947-22.571,49.379-19.814,60.001-17.464c2.517,11.465,4.915,37.782-17.825,60.521c-7.338,7.339-15.046,12.051-22.491,14.999
              c-1.451,0.466-2.912,0.981-4.382,1.548c-14.255,4.443-27.033,2.802-33.63,1.334c-0.74-3.333-1.524-8.228-1.553-14.085
              C265.99,244.337,265.991,244.252,265.991,244.167z M328.046,333.698c-23.078,23.079-49.823,20.252-60.487,17.886
              c-2.325-10.638-5.136-37.514,17.862-60.512c7.358-7.358,15.087-12.075,22.551-15.021c1.424-0.458,2.857-0.962,4.298-1.518
              c14.226-4.44,26.983-2.82,33.601-1.357C348.388,284.642,350.786,310.959,328.046,333.698z"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M309.356,5.571C291.925,1.875,273.973,0,256,0C187.62,0,123.333,26.628,74.981,74.981C26.629,123.333,0,187.62,0,256
              c0,56.978,19.17,112.597,54.096,157.381c-3.929,6.078-6.044,13.158-6.044,20.568c0,10.15,3.953,19.692,11.13,26.87
              c7.177,7.177,16.72,11.13,26.87,11.13s19.693-3.953,26.87-11.13c7.178-7.178,11.13-16.72,11.13-26.87
              c0-10.15-3.953-19.692-11.13-26.87s-16.72-11.131-26.87-11.131c-6.016,0-11.815,1.399-17.038,4.025
              C37.362,358.885,20,308.061,20,256C20,125.869,125.869,20,256,20c16.582,0,33.137,1.728,49.207,5.136
              c5.406,1.145,10.711-2.306,11.857-7.708C318.21,12.025,314.759,6.717,309.356,5.571z M73.324,421.22
              c3.509-3.51,8.118-5.264,12.728-5.264s9.219,1.755,12.728,5.264c3.4,3.399,5.272,7.92,5.272,12.728s-1.872,9.328-5.272,12.728
              c-7.018,7.02-18.438,7.018-25.456,0c-3.4-3.399-5.272-7.92-5.272-12.728S69.924,424.62,73.324,421.22z"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M457.904,98.619c3.929-6.078,6.044-13.158,6.044-20.568c0-10.15-3.953-19.693-11.13-26.87
              c-7.177-7.178-16.72-11.13-26.87-11.13c-10.15,0-19.693,3.953-26.87,11.13s-11.13,16.72-11.13,26.87
              c0,10.15,3.953,19.693,11.13,26.87c7.177,7.178,16.72,11.13,26.87,11.13c6.017,0,11.815-1.399,17.039-4.025
              C474.638,153.115,492,203.939,492,256c0,130.131-105.869,236-236,236c-13.999,0-28.025-1.234-41.688-3.669
              c-5.434-0.967-10.631,2.652-11.599,8.091c-0.969,5.437,2.653,10.63,8.09,11.599C225.622,510.661,240.828,512,256,512
              c68.38,0,132.667-26.629,181.02-74.98S512,324.38,512,256C512,199.022,492.83,143.403,457.904,98.619z M438.677,90.779
              c-3.399,3.4-7.92,5.272-12.728,5.272s-9.329-1.871-12.728-5.272c-7.019-7.018-7.019-18.437,0-25.456
              c3.399-3.4,7.92-5.272,12.728-5.272s9.328,1.872,12.728,5.272c3.4,3.399,5.272,7.92,5.272,12.728
              C443.949,82.859,442.077,87.379,438.677,90.779z"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M168.134,475.102l-3.776,9.26l3.748-9.271c-5.123-2.068-10.949,0.403-13.019,5.523c-2.07,5.12,0.403,10.949,5.523,13.02
              l0.309,0.122c1.179,0.451,2.393,0.665,3.587,0.665c4.028,0,7.835-2.438,9.359-6.415
              C175.841,482.847,173.292,477.077,168.134,475.102z"/>
          </g>
        </g>
        <g>
          <g>
            <path d="M351.824,18.543l-0.178-0.071c-5.127-2.052-10.947,0.443-12.999,5.571c-2.052,5.128,0.443,10.946,5.571,12.998
              l0.114,0.046c1.228,0.496,2.495,0.73,3.743,0.73c3.956,0,7.702-2.363,9.275-6.256C359.419,26.44,356.945,20.612,351.824,18.543z"
              />
          </g>
        </g>
        </svg>
    </StyleDiv>
  );
};

export default Icon;
