import React, { lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { useFetchProfile } from 'state/profile/hooks'
import { useSaveReferrer } from 'state/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import ResetCSS from './style/ResetCSS'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'
import Pools from './views/Pools'
import Swap from './views/Swap'
import ComingSoon from './views/ComingSoon'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity,
} from './views/AddLiquidity/redirects'
import RedirectOldRemoveLiquidityPathStructure from './views/RemoveLiquidity/redirects'
import { RedirectPathToSwapOnly, RedirectToSwap } from './views/Swap/redirects'
import Bridge from './views/Bridge'
import COLLATERALS from './views/COLLATERALS/index'


// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const MiniFarms = lazy(() => import('./views/MiniFarms'))
const FarmAuction = lazy(() => import('./views/FarmAuction'))
const NotFound = lazy(() => import('./views/NotFound'))
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
const Liquidity = lazy(() => import('./views/Pool'))
const PoolFinder = lazy(() => import('./views/PoolFinder'))
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))
const Membership = lazy(() => import('./views/Membership'))
const Project = lazy(() => import('./views/Dipo/components/Project'))
const PrivateShare = lazy(() => import('./views/PrivateShare/components/Project'))
const DetailProject = lazy(() => import('./views/Dipo/components/Project/ProjectDetail/index'))
const YourNFTs = lazy(() => import('./views/YourNFTs/NFTs'))
const RedeemTransferp2p = lazy(() => import('./views/Redeem/index'))
const NFTMartketplace = lazy(() => import('./views/NFTMartketplace/index'))
const SessionSwapLTD = lazy(() => import('./views/Migration/index'))
const PrivateShareProject = lazy(() => import('./views/PrivateShare/components/Project/ProjectDetail/index'))
const Voting = lazy(() => import('./views/Voting/index'))
const DetailsVote = lazy(() => import('./views/Voting/componets/Details/index'))
const CreateTopic = lazy(() => import('./views/CreateTopic/index'))
const MarketplaceDetails = lazy(() => import('./views/NFTMartketplace/componets/Details/index'))
const AirdropDetails = lazy(() => import('./views/Airdrop/componets/Details/index'))
const Airdrop = lazy(() => import('./views/Airdrop/index'))
const AdminRunToghether = lazy(() => import('./views/AdminBoxRuntogether/index'))
const SellPageRun = lazy(() => import('./views/AdminBoxRuntogether/components/SellPage'))
const BuyPage = lazy(() => import('./views/COLLATERALS/components/BuyPage'))
const PoolV2 = lazy(() => import('./views/PoolV2/index'))
const AdminSharesPage = lazy(() => import('./views/Shares/admin/index'))
const VLivetrade = lazy(() => import('./views/Shares/v_livetrade/v_livetrade_screen'))



// This config is required for number formatting Membership     
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()
  // useFetchProfile()
  // usePollCoreFarmData()
  useSaveReferrer()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      {/* <GlobalCheckClaimStatus excludeLocations={['/collectibles']} /> */}
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route exact path="/farms/auction">
              <FarmAuction />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/mini-farms">
              <MiniFarms />
            </Route>
            <Route path="/pools">
              <Pools />
            </Route>
            <Route path="/moon-pool">
              <PoolV2 />
            </Route>
            <Route path="/repo">
              <ComingSoon />
            </Route>
            <Route path="/membership">
              <Membership />
            </Route>
            <Route path="/bridge">
              <Bridge />
            </Route>
            <Route path="/nftmarketplace">
              <NFTMartketplace />
              {/* <ComingSoon/> */}
            </Route>
            <Route path="/mynfts">
              <YourNFTs />
            </Route>
            <Route path="/voting">
              <ComingSoon />
            </Route>
            <Route path="/createtopic">
              <ComingSoon />
            </Route>
            {/* <Route path="/adminruntogether"> 
              <AdminRunToghether/>
            </Route>
            <Route path="/market"> 
              <COLLATERALS/>
            </Route>          */}

            {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
            <Route exact strict path="/swap" component={Swap} />
            <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
            <Route exact strict path="/send" component={RedirectPathToSwapOnly} />
            <Route exact strict path="/find" component={PoolFinder} />
            <Route exact strict path="/liquidity" component={Liquidity} />
            <Route exact strict path="/create" component={RedirectToAddLiquidity} />
            <Route exact path="/add" component={AddLiquidity} />
            <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact path="/create" component={AddLiquidity} />
            <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
            <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
            <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} />
            <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
            <Route exact path="/redeem/:level" component={RedeemTransferp2p} />
            <Route exact path="/project/:project" component={DetailProject} />
            <Route exact path="/vote/:topic" component={ComingSoon} />
            <Route exact path="/privateshareproject/:project" component={PrivateShareProject} />
            <Route exact path="/airdropdetails/:airdroptId" component={AirdropDetails} />
            <Route exact path="/marketplace/:nftId" component={MarketplaceDetails} />
            <Route exact path="/detailsellruntogether/:boxid" component={SellPageRun} />
            <Route exact path="/detailbuy/:boxid" component={BuyPage} />

            <Route path="/dipo">
              <Project />
            </Route>
            <Route path="/privateshare">
              <PrivateShare />
            </Route>
            <Route path="/airdrop">
              <Airdrop />
            </Route>
            {/* Redirect */}
            <Route path="/pool">
              <Redirect to="/liquidity" />
            </Route>
            <Route path="/staking">
              <Redirect to="/pools" />
            </Route>
            <Route path="/syrup">
              <Redirect to="/pools" />
            </Route>
            <Route path="/nft">
              <Redirect to="/collectibles" />
            </Route>
            <Route path="/airdrop">
              <Airdrop />
            </Route>
            <Route path="/admin-pool">
              <AdminSharesPage />
            </Route>
            <Route path="/livetrade">
              <VLivetrade />
            </Route>


            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      {/* <EasterEgg iterations={2} /> */}
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)
