import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Menu as UikitMenu, useModal } from '@itdeltalabs/livetrade-uikit'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import { useLTDPriceOnus, useLiveTradeTokenPriceBusd } from 'state/farms/hooks'
import { useProfile } from 'state/profile/hooks'
import GlobalSettings from './GlobalSettings'
import config from './config'
import UserMenu from './UserMenu'
import { getActiveMenuItem, getActiveSubMenuItem } from './utils'


const Menu = (props) => {
  const { isDark, toggleTheme } = useTheme()
  const cakePriceUsd = useLTDPriceOnus()
  const { profile } = useProfile()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const location = useLocation()
  const pathname = location.pathname
  const activeMenuItem = getActiveMenuItem({ menuConfig: config(t), pathname })
  const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, pathname })
  return (
    <UikitMenu
      userMenu={<UserMenu/>}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code === "en" ? "vi" : "en"}
      langs={languageList}
      setLang={setLanguage}
      globalMenu={<GlobalSettings />}
      cakePriceUsd={cakePriceUsd}
      links={config(t)}
      activeItem={activeMenuItem?.href}
      activeSubItem={activeSubMenuItem?.href}
      profile={{
        username: profile?.username,
        image: profile?.nft ? `/images/nfts/${profile.nft?.images.sm}` : undefined,
        profileLink: '/profile',
        noProfileLink: '/profile',
        showPip: !profile?.username,
      }}
      {...props}
    />
  )
}

export default Menu
