import React from 'react'
import styled from 'styled-components'
import { Button, Heading, Text, LogoIcon } from '@itdeltalabs/livetrade-uikit'
import ButtonDigitalAssetsAndTokenizedStocks from 'views/Farms/components/ButtonDigitalAssetsAndTokenizedStocks'
import { useTranslation } from 'contexts/Localization'
import Nav from 'components/Menu/SubNav'

const StyledComingSoon = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: center;
`

const StyledTextComingSoon = styled.div`
  color: ${({ theme }) => (theme.isDark ? '#fff' : '#000')};
  font-size: 4vh;
  margin-top: 1rem;
  margin-bottom: 1rem;
`
const ContainerNav = styled.span`
width: 100%;
@media only screen and (max-width: 480px){
  padding: 0px 20px;
}
`

const Bridge = () => {
    const { t } = useTranslation()

    return (
        <Page>
            <StyledComingSoon>
              <ButtonDigitalAssetsAndTokenizedStocks/>
              <ContainerNav><Nav /></ContainerNav>
                <img src="/images/comingsoon_verion_1.svg" alt="Coming soon" />
                <StyledTextComingSoon>
                    Coming soon
                </StyledTextComingSoon>
                <Button as="a" href="/" scale="sm" style={{ color: "black" }} >
                    Back Home
                </Button>
            </StyledComingSoon>
        </Page>
    )
}

const Page = styled.div`
  background-image: url("/images/LiveTrade_BACKGROUND.svg");
`

export default Bridge
