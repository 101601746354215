import { createAsyncThunk } from "@reduxjs/toolkit";
import { Token } from "config/constants/types";
import { apiLiveTradeShares, ParamsGetAllLiveTradeSharesType } from "api-client";
import { getDataPool } from "./fetch_data";
import { Shares } from "./type";

export const fetchDataShares = createAsyncThunk<{ shares: Shares }, { account: string, token: Token, poolAddress: string }>(
    'storeShares/fetchDataShares',
    async ({ account, token, poolAddress }) => {
        const dataToken = await getDataPool(account, token, poolAddress);
        return dataToken
    }
)

export const getAllLiveTradeShares = createAsyncThunk('storeShares/getAllLiveTradeShares', async ({ skip, limit }: ParamsGetAllLiveTradeSharesType) => {
    const response = await apiLiveTradeShares.getAll({ skip, limit })
    return response
})

export const getOneLiveTradeShares = createAsyncThunk('storeShares/getOneLiveTradeShares', async (id: string) => {
    const response = await apiLiveTradeShares.getOne(id)
    return response
})

