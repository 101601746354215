import { poolsV2 } from "config/constants/pool_v2";
import React from "react";
// eslint-disable-next-line import/no-cycle
import { useAppDispatch } from "state";
import useRefresh from "hooks/useRefresh";
import { fetchPoolData } from "./actions";
import { getDataPool } from "./fetch_data";

export * from "./actions";
export * from "./reducer";


export const useCorePoolV2 = ({account, transactionHash}:{account:string, transactionHash: string}) => {
    const dispatch = useAppDispatch()
    const { slowRefresh } = useRefresh()
    React.useEffect(() => {
        (async () => {
            const dataToken = await getDataPool(account, poolsV2);
            dispatch(fetchPoolData(dataToken));
        })()
    }, [account, dispatch, transactionHash, slowRefresh]);
    
}
