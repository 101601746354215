import { Address, Token } from "./types";
import tokens from './tokens'
import contracts from "./contracts";

export interface PoolV2Config {
    id: number,
    stakingToken: Token,
    earningToken: Token,
    contractAddress: Address,
    stakeTokenPriceSymbol?: string,
    earnTokenPriceSymbol?: string,
    minStaking: string, // minStaking*1E18
    maxPool: string, // maxPool*1E18
    apy: number // 1% -> 100%
    poolPeriod: number, // period time of pool default 365
    unitTime: "day"|"minute", // default day
    unitPeriod: number // default 1 day,
    startStakeTime?: number
}

export const poolsV2: PoolV2Config[] = [
    {
        id: 1,
        stakingToken: tokens.livetrade,
        earningToken: tokens.livetrade,
        contractAddress: contracts.poolLTD,
        stakeTokenPriceSymbol: "LTDUSDT",
        earnTokenPriceSymbol: "LTDUSDT",
        minStaking: "1000000000000000000000",
        maxPool: "10000000000000000000000000",
        apy: 10,
        poolPeriod: 365,
        unitTime: "day",
        unitPeriod: 1,
        startStakeTime: 1714446000000
    }
]